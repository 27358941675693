export default [
  {
    path: '/admin/questionnaires',
    name: 'questionnaires-list',
    component: () => import('@/views/prs/questionnaires/questionnaires-list/QuestionnairesList.vue'),
    meta: {
      resource: 'AdminAccess',
      layout: 'horizontal',
      breadcrumb: [
        {
          text: 'Questionnaires List',
          active: true,
        },
      ],
    },
  },

  // Create Questionnaire
  {
    path: '/admin/questionnaires/create/:department',
    name: 'questionnaire-create',
    meta: {
      resource: 'AdminAccess',
      layout: 'horizontal',
      breadcrumb: [
        {
          text: 'Questionnaires List',
          to: '/admin/questionnaires',
          active: false,
        },
        {
          text: 'Create Questionnaire',
          active: true,
        },
      ],
    },
    component: () => import('@/views/prs/questionnaires/questionnaires-add/AddQuestionnaire.vue'),
  },

  // Edit Questionnaire
  {
    path: '/admin/questionnaires/edit/:id',
    name: 'questionnaire-edit',
    meta: {
      resource: 'AdminAccess',
      layout: 'horizontal',
      breadcrumb: [
        {
          text: 'Questionnaires List',
          to: '/admin/questionnaires',
          active: false,
        },
        {
          text: 'Edit Questionnaire',
          active: true,
        },
      ],
    },
    component: () => import('@/views/prs/questionnaires/questionnaires-edit/EditQuestionnaire.vue'),
  },

  // Edit Questionnaire
  {
    path: '/admin/questionnaires/view/:id',
    name: 'questionnaire-view',
    meta: {
      resource: 'AdminAccess',
      layout: 'horizontal',
      breadcrumb: [
        {
          text: 'Questionnaires List',
          to: '/admin/questionnaires',
          active: false,
        },
        {
          text: 'View Questionnaire',
          active: true,
        },
      ],
    },
    component: () => import('@/views/prs/questionnaires/questionnaire-view/ViewQuestionnaire.vue'),
  },
]
