// USERS routes
export default [

    {
        path: '/admin/users',
        name: 'users-list',
        component: () => import('@/views/prs/users/users-list/UsersList.vue'),
        meta: {
            // pageTitle: 'Users List',
            resource: 'AdminAccess',
            layout: 'horizontal',
            breadcrumb: [
                {
                    text: 'Users List',
                    active: true,
                },
            ],
        }
    },
    // {
    //     path: '/admin/users/view/:id',
    //     name: 'users-view',
    //     component: () => import('@/views/prs/users/users-view/UsersView.vue'),
    //     meta: {
    //         // pageTitle: 'View User',
    //         resource: 'AdminAccess',
    //         layout: 'horizontal',
    //         breadcrumb: [
    //             {
    //                 text: 'Users List',
    //                 to: '/admin/users',
    //                 active: false,
    //             },
    //             {
    //                 text: 'View User',
    //                 active: true,
    //             },
    //         ],
    //     }
    // },

]
