import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    projects: [
        {
            id: 1,
            name: "E-commerce Website",
            description: "A full-featured e-commerce platform with shopping cart and payment integration.",
            status: "In Progress"
        },
        {
            id: 2,
            name: "Social Media App",
            description: "A mobile application for connecting with friends and sharing updates.",
            status: "Completed"
        },
        {
            id: 3,
            name: "Inventory Management System",
            description: "A system to manage and track inventory levels, orders, and deliveries.",
            status: "In Progress"
        },
        {
            id: 4,
            name: "Online Learning Platform",
            description: "A platform offering online courses and certification programs.",
            status: "Completed"
        },
        {
            id: 5,
            name: "Project Management Tool",
            description: "A web-based tool for managing projects, tasks, and teams.",
            status: "In Progress"
        },
        {
            id: 6,
            name: "Customer Relationship Management (CRM)",
            description: "A system to manage company's interactions with current and potential customers.",
            status: "Planned"
        },
        {
            id: 7,
            name: "Weather Forecast App",
            description: "An app providing real-time weather updates and forecasts.",
            status: "Completed"
        },
        {
            id: 8,
            name: "Blog Platform",
            description: "A platform for projects to create and share blog posts.",
            status: "In Progress"
        },
        {
            id: 9,
            name: "Finance Tracker",
            description: "An application to track personal finances and generate reports.",
            status: "Planned"
        },
        {
            id: 10,
            name: "Fitness App",
            description: "An app offering workout routines, tracking, and health tips.",
            status: "Completed"
        },
        {
            id: 11,
            name: "Travel Booking System",
            description: "A system for booking flights, hotels, and rental cars.",
            status: "In Progress"
        },
        {
            id: 12,
            name: "Recipe Sharing App",
            description: "A platform for projects to share and discover new recipes.",
            status: "Completed"
        },
        {
            id: 13,
            name: "Task Scheduler",
            description: "An app for scheduling and managing daily tasks.",
            status: "Planned"
        },
        {
            id: 14,
            name: "Event Management System",
            description: "A system for planning and managing events and conferences.",
            status: "In Progress"
        },
        {
            id: 15,
            name: "Chat Application",
            description: "A real-time chat application with group and private messaging.",
            status: "Completed"
        },
        {
            id: 16,
            name: "Portfolio Website",
            description: "A personal website to showcase projects and skills.",
            status: "Completed"
        },
        {
            id: 17,
            name: "News Aggregator",
            description: "An application that aggregates news from various sources.",
            status: "In Progress"
        },
        {
            id: 18,
            name: "Job Portal",
            description: "A platform for job seekers to find and apply for jobs.",
            status: "Planned"
        },
        {
            id: 19,
            name: "Music Streaming Service",
            description: "A platform to stream and discover music.",
            status: "In Progress"
        },
        {
            id: 20,
            name: "Online Forum",
            description: "A forum for projects to discuss various topics and share information.",
            status: "Completed"
        },
        {
            id: 21,
            name: "Photo Gallery",
            description: "An application to upload and share photos.",
            status: "In Progress"
        },
        {
            id: 22,
            name: "Virtual Reality Experience",
            description: "A VR application offering immersive experiences.",
            status: "Planned"
        },
        {
            id: 23,
            name: "Language Learning App",
            description: "An app for learning new languages through interactive lessons.",
            status: "Completed"
        },
        {
            id: 24,
            name: "Marketplace",
            description: "A platform for buying and selling products.",
            status: "In Progress"
        },
        {
            id: 25,
            name: "Gaming Platform",
            description: "A platform for playing and streaming video games.",
            status: "Planned"
        }
    ]
};

/* eslint-enable */

// ------------------------------------------------
// GET: Return projects
// ------------------------------------------------
mock.onGet('/prs/projects').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.projects.filter(
        project =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            project.name.toLowerCase().includes(queryLowered) &&
            project.status === (status || project.status),
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()

    return [
        200,
        {
            projects: paginateArray(sortedData, perPage, page),
            total: filteredData.length,
        },
    ]
})

// ------------------------------------------------
// POST: Add new project
// ------------------------------------------------
mock.onPost('/prs/projects').reply(config => {
    // Get event from post data
    const { project } = JSON.parse(config.data)

    // Assign Status
    project.status = 'active'

    const { length } = data.projects
    let lastIndex = 0
    if (length) {
        lastIndex = data.projects[length - 1].id
    }
    project.id = lastIndex + 1

    data.projects.push(project)

    return [201, { project }]
})

// ------------------------------------------------
// GET: Return Single project
// ------------------------------------------------
mock.onGet(/\/prs\/projects\/\d+/).reply(config => {
    // Get event id from URL
    let projectId = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    projectId = Number(projectId)

    const projectIndex = data.projects.findIndex(e => e.id === projectId)
    const project = data.projects[projectIndex]

    if (project) return [200, project]
    return [404]
})
