export default [
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/prs/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
      meta: {
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
      meta: {
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/prs/auth/ResetPassword.vue'),
      meta: {
        resource: 'InactiveUserAccess',
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/prs/profile/AccountSetting.vue'),
      meta: {
        layout: 'horizontal',
        resource: 'UserAccess',
        // pageTitle: 'Profile Settings',
        breadcrumb: [
          {
            text: 'Profile Settings',
            active: true,
          },
        ],
      },
    },  
  ]
  