import main from './main'
import misc from './misc'
import auth from './auth'
import users from './users'
import levels_locations from './levels_locations'
import surveys from './surveys'
import questionnaires from './questionnaires'
// import projects from './projects'
import results from './results'
import dashboard from './dashboard'


export default [
    ...main,
    ...auth,
    ...users,
    ...levels_locations,
    ...surveys,
    ...questionnaires,
    ...results,
    // ...projects,
    ...dashboard,
    ...misc,
    
]
