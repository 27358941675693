export default [
    // Levels routes
    {
        path: '/admin/levels-locations',
        name: 'levels-locations-list',
        component: () => import('@/views/prs/combined/LevelsLocations.vue'),
        meta: {
            // pageTitle: 'Levels List',
            resource: 'AdminAccess',
            layout: 'horizontal',
            breadcrumb: [
                {
                    text: 'Levels & Locations List',
                    active: false,
                },
            ],
        }
    },
    // {
    //     path: '/admin/levels/edit/:id',
    //     name: 'levels-edit',
    //     component: () => import('@/views/prs/levels/levels-list/LevelsList.vue'),
    //     meta: {
    //         // pageTitle: 'Edit Level',
    //         resource: 'AdminAccess',
    //         layout: 'horizontal',
    //         breadcrumb: [
    //             {
    //                 text: 'Levels List',
    //                 to: '/admin/levels',
    //                 active: false,
    //             },
    //             {
    //                 text: 'Edit Level',
    //                 active: true,
    //             },
    //         ],
    //     }
    // },
    // {
    //     path: '/admin/levels/view/:id',
    //     name: 'levels-view',
    //     component: () => import('@/views/prs/levels/levels-list/LevelsList.vue'),
    //     meta: {
    //         // pageTitle: 'View Level',
    //         resource: 'AdminAccess',
    //         layout: 'horizontal',
    //         breadcrumb: [
    //             {
    //                 text: 'Levels List',
    //                 to: '/admin/levels',
    //                 active: false,
    //             },
    //             {
    //                 text: 'View Level',
    //                 active: true,
    //             },
    //         ],
    //     }
    // },
]
