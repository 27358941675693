export default [
    // {
    //   path: '/admin/dashboard',
    //   name: 'admin-dashboard',
    //   meta: {
    //     resource: 'AdminAccess'
    //   },
    //   component: () => import('@/views/prs/dashboard/ecommerce/Ecommerce.vue'),
    // },
    {
      path: '/admin/dashboard',
      name: 'admin-dashboard',
      meta: {
        resource: 'AdminAccess'
      },
      component: () => import('@/views/prs/dashboard/Dashboard.vue'),
    },
  ]