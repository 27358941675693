export default [
  // Admin Survey Routes
  {
    path: '/admin/surveys',
    name: 'surveys-list',
    meta: {
      resource: 'AdminAccess',
      breadcrumb: [
        {
          text: 'All Surveys',
          active: true,
        },
      ],
    },

    component: () => import('@/views/prs/surveys/surveys-list/SurveysList.vue'),
  },
  // User Survey Routes
  {
    path: '/surveys/fill/:id',
    name: 'survey-fill',
    meta: {
      resource: 'UserAccess',
      breadcrumb: [
        {
          text: 'My surveys',
          to: '/',
          active: false,
        },
        {
          text: 'Fill Survey',
          active: true,
        },
      ],
    },

    component: () => import('@/views/prs/surveys/FillSurvey.vue'),
  },
  {
    path: '/surveys/view/:id',
    name: 'survey-view',
    meta: {
      resource: 'UserAccess',
      breadcrumb: [
        {
          text: 'My surveys',
          to: '/',
          active: false,
        },
        {
          text: 'View Survey',
          active: true,
        },
      ],
    },
    component: () => import('@/views/prs/surveys/ViewSurvey.vue'),
  },
]
